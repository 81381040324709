import { Component, Input, OnInit } from '@angular/core';

import { IconDefinition, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { ReviewService } from '../../../shared/services/review.service';

@Component({
    selector: 'app-sort-filter',
    styleUrls: ['./sort-filter.component.scss'],
    templateUrl: './sort-filter.component.html',
    standalone: false
})
export class SortFilterComponent implements OnInit {
    @Input() public sortFiltersCollapsed: boolean;
    @Input() public defaultValue?: number;

    public faAngleDown: IconDefinition = faAngleDown;
    public faAngleUp: IconDefinition = faAngleUp;
    public sortOptions: any[] = [
        { name: 'Score-oplopend', type: 2 },
        { name: 'Score-aflopend', type: 1 },
        { name: 'Datum-oplopend', type: 4 },
        { name: 'Datum-aflopend', type: 3 }
    ];

    public selectedSortOption: number;

    constructor(private reviewService: ReviewService) {}

    public ngOnInit(): void {
        this.setDefaultValue();
    }

    public updateSortOptions(sortType: number, passive?: boolean): void {
        const getReviewRequest = this.reviewService.getManagementReviewsRequestValue();

        getReviewRequest.sortType = sortType;
        getReviewRequest.page = 1;
        getReviewRequest.passive = passive === undefined || passive === false ? false : true;

        this.reviewService.setGetManagementReviewsRequest(getReviewRequest);
    }

    public setDefaultValue(): void {
        if (this.defaultValue !== null && this.defaultValue !== undefined) {
            this.sortOptions
                .filter((option) => option.type === this.defaultValue)
                .forEach((option) => (this.selectedSortOption = option.type));

            this.updateSortOptions(this.selectedSortOption, true);
        }
    }
}
