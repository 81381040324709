import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';

import { ImageService } from '../services/image.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ImageComponent implements OnInit, OnChanges {
    @Input() public value = '';
    @Input() public valueType = '';
    @Input() public imgClass = '';

    public imageUrl: string;

    constructor(private imageService: ImageService) {}

    public ngOnInit(): void {
        if (this.value !== null && this.value !== undefined) {
            this.getImageFromService(this.value, this.valueType);
        }
    }

    public ngOnChanges(): void {
        if (this.value !== null && this.value !== undefined) {
            this.getImageFromService(this.value, this.valueType);
        }
    }

    private getImageFromService(value: string, valueType: string): void {
        if (valueType === 'id' || (valueType === 'name' && value !== null)) {
            this.imageUrl = this.imageService.getImageUrlbyName(value, valueType);
        }
    }
}
