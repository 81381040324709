import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewChecked, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { IconDefinition, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-accordion',
    animations: [
        trigger('enterAnimation', [
            transition(':enter', [
                style({
                    height: 0
                }),
                animate(
                    '200ms',
                    style({
                        height: 100
                    })
                )
            ]),
            transition(':leave', [
                style({
                    height: 100
                }),
                animate(
                    '200ms',
                    style({
                        height: 0
                    })
                )
            ])
        ])
    ],
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    standalone: false
})
export class AccordionComponent implements AfterViewChecked {
    @Input() public title: string;
    @Input() public info: string;

    public faAngleDown: IconDefinition = faAngleDown;
    public faAngleUp: IconDefinition = faAngleUp;
    public open: boolean;
    public infoHtml: SafeHtml;

    constructor(protected sanitizer: DomSanitizer) {}

    public ngAfterViewChecked(): void {
        if (this.info !== undefined || this.info !== null) {
            this.infoHtml = this.sanitizer.bypassSecurityTrustHtml(this.info);
        }
    }
}
