import { Component, Input, OnInit } from '@angular/core';

import { IconDefinition, faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-stars',
    templateUrl: './stars.component.html',
    styleUrls: ['./stars.component.scss'],
    standalone: false
})
export class StarsComponent implements OnInit {
    @Input() public rating: number;
    @Input() public min: number;
    @Input() public max: number;
    @Input() public size: string;

    public faStar: IconDefinition = faStar;
    public ratingPercentage = '0%';

    public ngOnInit(): void {
        if (this.rating !== undefined && this.min !== undefined && this.max !== undefined) {
            this.calculateRatingPercentage(this.min, this.max, this.rating);
        }
    }

    private calculateRatingPercentage(min: number, max: number, rating: number): void {
        const fullscale = max;
        const ratingPercentage = (rating / fullscale) * 100;
        this.ratingPercentage = ratingPercentage + '%';
    }
}
