import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, HostBinding, NgModule } from '@angular/core';
import * as i1 from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
class NgxJsonLdComponent {
  constructor(sanitizer) {
    this.sanitizer = sanitizer;
  }
  set json(currentValue) {
    this.jsonLD = this.getSafeHTML(currentValue);
  }
  getSafeHTML(value) {
    const json = value ? JSON.stringify(value, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
NgxJsonLdComponent.ɵfac = function NgxJsonLdComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxJsonLdComponent)(i0.ɵɵdirectiveInject(i1.DomSanitizer));
};
NgxJsonLdComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgxJsonLdComponent,
  selectors: [["ngx-json-ld"]],
  hostVars: 1,
  hostBindings: function NgxJsonLdComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵhostProperty("innerHTML", ctx.jsonLD, i0.ɵɵsanitizeHtml);
    }
  },
  inputs: {
    json: "json"
  },
  standalone: false,
  decls: 0,
  vars: 0,
  template: function NgxJsonLdComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxJsonLdComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-json-ld',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: i1.DomSanitizer
    }];
  }, {
    json: [{
      type: Input
    }],
    jsonLD: [{
      type: HostBinding,
      args: ['innerHTML']
    }]
  });
})();
class NgxJsonLdModule {}
NgxJsonLdModule.ɵfac = function NgxJsonLdModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxJsonLdModule)();
};
NgxJsonLdModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxJsonLdModule
});
NgxJsonLdModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxJsonLdModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [NgxJsonLdComponent],
      exports: [NgxJsonLdComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-json-ld
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxJsonLdComponent, NgxJsonLdModule };
