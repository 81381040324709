import { AfterViewInit, Component, Input } from '@angular/core';

import { IconDefinition, faAngleDown, faAngleUp, faFilter } from '@fortawesome/free-solid-svg-icons';

import { FilterMenuConfig } from '../../models/filter-menu-config.model';
import { ReviewService } from '../../shared/services/review.service';

@Component({
    selector: 'app-filter-menu',
    styleUrls: ['./filter-menu.component.scss'],
    templateUrl: './filter-menu.component.html',
    standalone: false
})
export class FilterMenuComponent implements AfterViewInit {
    @Input() public device: string;
    @Input() public showMobile: boolean;
    @Input() public config: FilterMenuConfig;

    public faFilter: IconDefinition = faFilter;
    public faAngleDown: IconDefinition = faAngleDown;
    public faAngleUp: IconDefinition = faAngleUp;
    public showMobileMenu = false;

    constructor(private reviewService: ReviewService) {}

    public ngAfterViewInit(): void {
        const initialReviewRequest = this.reviewService.getManagementReviewsRequestValue();
        initialReviewRequest.passive = false;
        this.reviewService.setGetManagementReviewsRequest(initialReviewRequest);
    }

    public closeMobileMenu(event: Event): void {
        event.stopPropagation();

        if (this.device === 'mobile') {
            this.showMobileMenu = false;
        }
    }

    public openMobileMenu(event: Event): void {
        event.stopPropagation();

        if (this.device === 'mobile') {
            this.showMobileMenu = true;
        }
    }
}
