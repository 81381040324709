import { Component, Input } from '@angular/core';

import { IconDefinition, faAngleDown, faAngleUp, faBan, faFilter, faSearch, faUndo } from '@fortawesome/free-solid-svg-icons';

import { GetDataTypeAheadRequest } from '../../../models/get-data-type-ahead-request.model';
import { GetDataTypeAheadResult } from '../../../models/get-data-type-ahead-result.model';
import { DataService } from '../../../shared/services/data.service';
import { ReviewService } from '../../../shared/services/review.service';

@Component({
    selector: 'app-data-filter',
    styleUrls: ['./data-filter.component.scss'],
    templateUrl: './data-filter.component.html',
    standalone: false
})
export class DataFilterComponent {
    @Input() public dataFiltersCollapsed: boolean;

    public getDataTypeAheadResult: GetDataTypeAheadResult[] = new Array<GetDataTypeAheadResult>();
    public listOfSelectedData: GetDataTypeAheadResult[] = new Array<GetDataTypeAheadResult>();
    public nextDataFilterLevel = 0;
    public selectedParentId = 0;
    public faSearch: IconDefinition = faSearch;
    public faBan: IconDefinition = faBan;
    public faUndo: IconDefinition = faUndo;
    public faFilter: IconDefinition = faFilter;
    public faAngleDown: IconDefinition = faAngleDown;
    public faAngleUp: IconDefinition = faAngleUp;
    public searchTerm: string;

    constructor(
        private dataService: DataService,
        private reviewService: ReviewService
    ) {
        this.setGetDataTypeAheadRequest();
        this.subscribeToGetDataTypeAheadResult();
    }

    public subscribeToGetDataTypeAheadResult(): void {
        this.dataService.getDataTypeAheadResponseSubscription().subscribe((getDataTypeAheadResult) => {
            this.getDataTypeAheadResult = getDataTypeAheadResult;
        });
    }

    public setGetDataTypeAheadRequest(): void {
        const getDataTypeAheadRequest: GetDataTypeAheadRequest = new GetDataTypeAheadRequest();
        getDataTypeAheadRequest.levelFilter = 0;
        this.dataService.setGetDataTypeAheadRequest(getDataTypeAheadRequest);
    }

    public searchOnInput(): void {
        const getDataTypeAheadRequest: GetDataTypeAheadRequest = this.dataService.getDataTypeAheadRequestValue();
        getDataTypeAheadRequest.levelFilter = this.nextDataFilterLevel;
        getDataTypeAheadRequest.searchString = this.searchTerm;
        this.dataService.setGetDataTypeAheadRequest(getDataTypeAheadRequest);
    }

    public selectDataFilter(getDataTypeAheadResult: GetDataTypeAheadResult): void {
        if (this.listOfSelectedData.some((data) => data.id === getDataTypeAheadResult.id)) {
            return;
        }

        this.nextDataFilterLevel++;
        this.listOfSelectedData.push(getDataTypeAheadResult);
        this.searchTerm = '';
        this.selectedParentId = getDataTypeAheadResult.id;
        this.updateGetDataTypeAheadRequest();
        this.updateReviewRequest();
    }

    public clearFilters(): void {
        this.listOfSelectedData = new Array<GetDataTypeAheadResult>();
        this.nextDataFilterLevel = 0;
        this.searchTerm = '';
        this.selectedParentId = undefined;
        this.updateGetDataTypeAheadRequest();
        this.updateReviewRequest();
    }

    public removeLastFilters(): void {
        if (this.listOfSelectedData.length === 0) {
            return;
        }

        this.listOfSelectedData.pop();
        this.nextDataFilterLevel--;
        this.searchTerm = '';

        const selectFilter = this.listOfSelectedData.filter((x) => x.level == this.nextDataFilterLevel - 1);

        if (selectFilter.length > 0) {
            this.selectedParentId = selectFilter[0].id;
        } else {
            this.selectedParentId = undefined;
        }

        this.updateGetDataTypeAheadRequest();
        this.updateReviewRequest();
    }

    public updateGetDataTypeAheadRequest(): void {
        const getDataTypeAheadRequest: GetDataTypeAheadRequest = new GetDataTypeAheadRequest();

        getDataTypeAheadRequest.levelFilter = this.nextDataFilterLevel;
        getDataTypeAheadRequest.searchString = this.searchTerm;

        if (this.selectedParentId != undefined) {
            getDataTypeAheadRequest.parentId = this.selectedParentId;
        }

        this.dataService.setGetDataTypeAheadRequest(getDataTypeAheadRequest);
    }

    public updateReviewRequest(): void {
        const getReviewRequest = this.reviewService.getManagementReviewsRequestValue();
        const dataIdArray: number[] = this.listOfSelectedData.map((selectedData) => selectedData.id);

        getReviewRequest.dataIds = dataIdArray;
        getReviewRequest.page = 1;
        getReviewRequest.passive = false;

        this.reviewService.setGetManagementReviewsRequest(getReviewRequest);
    }
}
