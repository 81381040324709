import { Pipe, PipeTransform } from '@angular/core';

import { DataViewType } from '../../models/data-view-type.enum';

@Pipe({
    name: 'DataViewTypeTotext',
    pure: false,
    standalone: false
})
export class DataViewTypeTotext implements PipeTransform {
    public transform(type: DataViewType): string {
        switch (type) {
            case DataViewType.Block:
                return 'Blok weergave';
            case DataViewType.Filter:
                return 'Detail weergave';
            case DataViewType.List:
                return 'Lijst weergave';
            default:
                return '';
        }
    }
}
