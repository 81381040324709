import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IconDefinition, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { KeyValuePair } from '../../models/key-value-pair.model';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    standalone: false
})
export class DropdownComponent {
    @Input() public values: KeyValuePair[];
    @Input() public title: string;
    @Input() public selectedValue: KeyValuePair;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() public onSelect: EventEmitter<KeyValuePair> = new EventEmitter<KeyValuePair>();

    public faAngleDown: IconDefinition = faAngleDown;
    public faAngleUp: IconDefinition = faAngleUp;

    public showDropdownContent = false;

    public valueSelected(value: KeyValuePair): void {
        this.showDropdownContent = !this.showDropdownContent;
        this.selectedValue = value;
        this.onSelect.emit(value);
    }
}
