import { Component, Input, OnInit } from '@angular/core';

import { IconDefinition, faAdjust, faBan, faCheckDouble, faSync, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ApprovalOverview } from '../../models/approval-overview.model';
import { GetManagementReviewsRequest } from '../../models/get-management-reviews-request.model';
import { GetReviewByFilterResponse } from '../../models/get-review-by-filter-response.model';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { ReviewData } from '../../models/review-data.model';
import { ReviewManagementListConfig } from '../../models/review-management-list-config.model';
import * as reviewStatusEnum from '../../models/review-status.enum';
import { ReviewStatusEnum } from '../../models/review-status.enum';
import { UpdateReviewStatusRequest } from '../../models/update-review-status-request.model';
import { UpdateReviewStatussenRequest } from '../../models/update-review-statussen-request.model';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { ReviewService } from '../services/review.service';
import { Utilities } from '../services/utilities.service';

@Component({
    selector: 'app-review-management-list',
    styleUrls: ['./review-management-list.component.scss'],
    templateUrl: './review-management-list.component.html',
    standalone: false
})
export class ReviewManagementListComponent implements OnInit {
    @Input() public config: ReviewManagementListConfig;

    public bsModalRef: BsModalRef;
    public itemsPerPage: KeyValuePair = { key: '10', value: 10 };
    public getManagementReviewsResponse: GetReviewByFilterResponse = new GetReviewByFilterResponse();
    public approvalOverview: ApprovalOverview = new ApprovalOverview();
    public currentPage: number;
    public currentReviewRequest: GetManagementReviewsRequest;
    public faAdjust: IconDefinition = faAdjust;
    public faThumbsDown: IconDefinition = faThumbsDown;
    public faThumbsUp: IconDefinition = faThumbsUp;
    public faCheckDouble: IconDefinition = faCheckDouble;
    public faBan: IconDefinition = faBan;
    public faSync: IconDefinition = faSync;
    public reviewStatusEnumType = reviewStatusEnum.ReviewStatusEnum;

    constructor(
        private reviewService: ReviewService,
        private modalService: BsModalService,
        private utilities: Utilities
    ) {
        this.setGetManagementReviewsRequest();
        this.subscribeToGetManagementReviewsResponse();
        this.subscribeToGetManagementReviewsRequest();
    }

    public ngOnInit(): void {
        this.itemsPerPage = this.config.itemsPerPageValues[this.config.itemsPerPageDefaultIndex];
    }

    public subscribeToGetManagementReviewsResponse(): void {
        this.reviewService.getManagementReviewsResponseSubscription().subscribe((getReviewByFilterResponse) => {
            this.getManagementReviewsResponse = getReviewByFilterResponse;
            this.updatePendingNumbers();
        });
    }

    public subscribeToGetManagementReviewsRequest(): void {
        this.reviewService.getManagementReviewsRequestObservable().subscribe((getManagementReviewsRequest) => {
            this.currentPage = getManagementReviewsRequest.page;
            this.currentReviewRequest = getManagementReviewsRequest;
        });
    }

    public setGetManagementReviewsRequest(): void {
        const getManagementReviewsRequest: GetManagementReviewsRequest = new GetManagementReviewsRequest();

        getManagementReviewsRequest.itemsPerPage = 10;
        getManagementReviewsRequest.page = 1;
        getManagementReviewsRequest.status = ReviewStatusEnum.Pending;

        this.reviewService.setGetManagementReviewsRequest(getManagementReviewsRequest);
    }

    public updatePendingNumbers(): void {
        this.approvalOverview.approvedPendingReviews = this.getManagementReviewsResponse.reviewViewModels.filter(
            (review) => review.currentStatus === ReviewStatusEnum.Accepted
        ).length;

        this.approvalOverview.rejectedPendingReviews = this.getManagementReviewsResponse.reviewViewModels.filter(
            (review) => review.currentStatus === ReviewStatusEnum.Rejected
        ).length;

        this.approvalOverview.totalPendingReviews =
            this.approvalOverview.approvedPendingReviews + this.approvalOverview.rejectedPendingReviews;

        this.reviewService.setApprovalOverview(this.approvalOverview);
    }

    public pageChanged(event: any): void {
        const getManagementReviewsRequest: GetManagementReviewsRequest = this.reviewService.getManagementReviewsRequestValue();
        getManagementReviewsRequest.page = event.page;
        this.reviewService.setGetManagementReviewsRequest(getManagementReviewsRequest);
    }

    public updateItemsPerPageCount(value: KeyValuePair): void {
        this.itemsPerPage = value;
        const getManagementReviewsRequest: GetManagementReviewsRequest = this.reviewService.getManagementReviewsRequestValue();
        getManagementReviewsRequest.itemsPerPage = this.itemsPerPage.value;
        this.reviewService.setGetManagementReviewsRequest(getManagementReviewsRequest);
    }

    public rejectReview(id: number): void {
        const modalOptions = {
            initialState: {
                title: 'Review afkeuren',
                question: 'Weet u zeker dat u deze review wilt afkeuren?',
                answerPositive: 'Ja',
                answerNegative: 'Nee',
                backdrop: true,
                ignoreBackdropClick: true
            },
            ignoreBackdropClick: true,
            backdrop: true,
            class: 'modal-dialog-centered'
        };

        this.bsModalRef = this.modalService.show(ConfirmationModalComponent, modalOptions);
        this.bsModalRef.content.onClose.subscribe((result) => {
            if (result === true) {
                const updateReviewStatussenRequest = new UpdateReviewStatussenRequest();
                const updateReviewStatusRequest = new UpdateReviewStatusRequest();

                updateReviewStatusRequest.reviewId = id;
                updateReviewStatusRequest.status = ReviewStatusEnum.Rejected;
                updateReviewStatussenRequest.updateReviewStatusRequests.push(updateReviewStatusRequest);

                this.reviewService.updateReviewStatussen(updateReviewStatussenRequest);
            }
        });
    }

    public updateReviewStatuses(): void {
        this.reviewService.updateReviewStatussen(this.reviewService.mapGetManagementReviewsResponseToUpdateReviewStatussenRequest());
    }

    public acceptReview(id: number): void {
        const modalOptions = {
            initialState: {
                title: 'Review Goedkeuren',
                question: 'Weet u zeker dat u deze review wilt goedkeuren?',
                answerPositive: 'Ja',
                answerNegative: 'Nee',
                backdrop: true,
                ignoreBackdropClick: true
            },
            ignoreBackdropClick: true,
            backdrop: true,
            class: 'modal-dialog-centered'
        };

        this.bsModalRef = this.modalService.show(ConfirmationModalComponent, modalOptions);
        this.bsModalRef.content.onClose.subscribe((result) => {
            if (result === true) {
                const updateReviewStatussenRequest = new UpdateReviewStatussenRequest();
                const updateReviewStatusRequest = new UpdateReviewStatusRequest();

                updateReviewStatusRequest.reviewId = id;
                updateReviewStatusRequest.status = ReviewStatusEnum.Accepted;
                updateReviewStatussenRequest.updateReviewStatusRequests.push(updateReviewStatusRequest);

                this.reviewService.updateReviewStatussen(updateReviewStatussenRequest);
            }
        });
    }

    public refreshPage(): void {
        const getManagementReviewsRequestValue = this.reviewService.getManagementReviewsRequestValue();
        this.reviewService.setGetManagementReviewsRequest(getManagementReviewsRequestValue);
    }

    public createPaginationSubText(): string {
        return this.utilities.createPaginationSubText(
            this.currentPage,
            this.itemsPerPage.value,
            this.getManagementReviewsResponse.reviewViewModels.length,
            this.getManagementReviewsResponse.reviewCount
        );
    }

    public anyNonActiveLevels(reviewData: ReviewData[]): boolean {
        const anyInactives = reviewData.some((r) => !r.data.active);
        return anyInactives;
    }
}
