import { Component, Input, OnInit } from '@angular/core';

import { IconDefinition, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { ReviewStatusEnum } from '../../../models/review-status.enum';
import { ReviewService } from '../../../shared/services/review.service';

@Component({
    selector: 'app-status-filter',
    styleUrls: ['./status-filter.component.scss'],
    templateUrl: './status-filter.component.html',
    standalone: false
})
export class StatusFilterComponent implements OnInit {
    @Input() public statusFiltersCollapsed: boolean;
    @Input() public defaultValue?: number;
    @Input() public statusOptionsWhitelist?: ReviewStatusEnum[];

    public faAngleDown: IconDefinition = faAngleDown;
    public faAngleUp: IconDefinition = faAngleUp;
    public statusOptions: any[] = [
        { name: 'Onbeslist', type: ReviewStatusEnum.Pending },
        { name: 'Afgekeurd', type: ReviewStatusEnum.Rejected },
        { name: 'Goedgekeurd', type: ReviewStatusEnum.Accepted },
        { name: 'Vulgair taalgebruik', type: ReviewStatusEnum.Sanitized },
        { name: 'Allemaal', type: null }
    ];

    public selectedStatusOption: number | null = null;

    constructor(private reviewService: ReviewService) {}

    public ngOnInit(): void {
        if (this.statusOptionsWhitelist) {
            this.statusOptions = this.statusOptions.filter((s) => this.statusOptionsWhitelist.includes(s.type as ReviewStatusEnum));
        }

        this.setDefaultValue();
    }

    public updateStatusOptions(statusType: number | null, passive?: boolean): void {
        const getReviewRequest = this.reviewService.getManagementReviewsRequestValue();

        getReviewRequest.status = statusType;
        getReviewRequest.page = 1;
        getReviewRequest.passive = passive === undefined || passive === false ? false : true;

        this.reviewService.setGetManagementReviewsRequest(getReviewRequest);
    }

    public setDefaultValue(): void {
        if (this.defaultValue !== null && this.defaultValue !== undefined) {
            this.statusOptions
                .filter((option) => option.type === this.defaultValue)
                .forEach((option) => (this.selectedStatusOption = option.type));

            this.updateStatusOptions(this.selectedStatusOption, true);
        }
    }
}
